<template>
  <div class="card overflow-hidden h-auto-card-header">
    <div class="image-gradient" :style="background_image" v-if="!is_in_creator_view"></div>
    <div class="container-avatar-title-description d-flex flex-wrap flex-md-nowrap justify-content-center w-100">
      <b-img
        v-if="loaded_children_service"
        :src="avatar"
        rounded
        fluid
        :alt="headerData.username"
        class="b-avatar-profile-header d-block"
        @click="openToNewTab()"
      />
      <b-skeleton class="b-avatar-profile-header d-block mt-1 mt-md-0" v-else></b-skeleton>

      <div :class="`ml-md-2 w-100`" v-if="loaded_children_service">
        <h2 class="text-center text-md-left mt-1 mt-md-0">
          <b-link :href="linkNetwork" target="_blank" class="text-white">
            {{ headerData.username }}
          </b-link>
          <image-verified v-if="headerData.is_verified"  class="icon-check"></image-verified>
        </h2>
        <div class="text-center text-md-left text-white d-none d-md-block container-p-description-designation">
          {{ headerData.designation }}
        </div>
        <p class="text-white mb-1 d-none d-md-block">
            {{$t('instagram.Updatedreport')}}  <span v-if="$i18n.locale === 'es'">{{getDate(headerData.updated_at)[0]}} de {{ $t(`profile.months.${getDate(headerData.updated_at)[1]}`)}} del {{getDate(headerData.updated_at)[2]}}</span>
          <span v-if="$i18n.locale === 'en'">{{getDate(headerData.updated_at)[0]}} {{ $t(`profile.months.${getDate(headerData.updated_at)[1]}`)}} {{getDate(headerData.updated_at)[2]}}</span>
        </p>
      </div>
      <div class="skeleton ml-md-2 w-100 d-flex d-md-block justify-content-center justify-content-md-start" v-else>
        <b-skeleton class="col-2 h-28"></b-skeleton>
        <b-skeleton class="col-5 h-30 d-none d-md-block"></b-skeleton>
      </div>
    </div>

    <div class="bg-white span-geo-profile d-flex align-items-center" v-if="loaded_children_service">
      <div v-if="geo !== null" class="mr-1">
        <feather-icon icon="MapPinIcon" class="text-muted"/>
        <span>{{ geo }}</span>
      </div>
      <div v-if="languages.length > 0">
        <feather-icon icon="BookOpenIcon" class="text-muted"/>
        <span>{{ languages }}</span>
      </div>
    </div>
    <b-skeleton class="p-1 bg-white span-geo-profile col-1 h-30 mb-0" v-else></b-skeleton>
   
    <div class="container-dropdowns-actions d-flex justify-content-between flex-wrap flex-xl-nowrap w-100">
      <div class="d-flex flex-wrap flex-lg-nowrap mb-2 mb-xl-0 w-100">
        <div class="container-name-influencer text-center text-lg-left mr-xl-3 w-100 w-xl-auto mb-2 mb-xl-0" v-if="loaded_children_service">
          <h5>{{ headerData.name }}</h5> 
          <p>{{ getFormat(total_audience) }} de audiencia total</p>
          <p>{{ children_data.country }} - {{ children_data.city }}</p>
        </div>
        <div v-else class="col-12 col-md-2 pt-2 pt-md-0 skeleton d-flex justify-content-center d-mb-block flex-wrap">
          <b-skeleton class="col-12 h-18"></b-skeleton>
          <b-skeleton class="mb-02 col-11"></b-skeleton>
          <b-skeleton class="mb-02 col-8"></b-skeleton>
        </div>
        <div class="d-flex flex-wrap flex-md-nowrap flex-lg-wrap align-items-lg-start w-100 w-xl-auto" v-if="loaded_children_service">
          <div class="d-flex w-100 w-xl-auto">
            <b-button class="button-add-saved-profile-header mr-05 mb-1" variant="button-add-saved-profile-header">
              <b-icon icon="bookmark-fill"></b-icon>
            </b-button>
            <b-button class="blue-button px-3 mb-1 w-100" variant="blue-button"><feather-icon icon="PlusIcon"></feather-icon> Añadir a...</b-button>
          </div>
          <b-button-group class="btn-email ml-05-r mb-1 w-100 col-xl p-xl-0" v-if="email_influencer !== null">
            <b-button variant="outline-secondary" class="p-09" @click="copyAction(email_influencer)">{{ email_influencer }}</b-button>
            <b-button variant="outline-secondary" class="px-05" id="tooltip_copy"  @click="copyAction(email_influencer)"><feather-icon icon="CopyIcon" size="16"/></b-button>
          </b-button-group>
        </div>
        <div class="skeleton col-12 col-md-4 d-flex justify-content-center d-md-block mt-2 mt-md-0" v-else>
          <b-skeleton class="col-8 h-28"></b-skeleton>
        </div>
      </div>
        
      <div class="d-flex flex-wrap flex-xl-nowrap justify-content-center align-items-xl-start w-100 w-xl-auto" v-if="loaded_children_service">
        <b-button class="button-no-borders d-flex flex-column align-items-center"><feather-icon icon="StarIcon" size="18" class="mb-04"></feather-icon>Favoritos</b-button>
        <b-button class="button-no-borders d-flex flex-column align-items-center" @click="copyAction(window.location.href, true)"><feather-icon icon="CopyIcon" size="18" class="mb-04"></feather-icon>Copiar</b-button>
        <b-button class="button-no-borders d-flex flex-column align-items-center mr-2"><feather-icon icon="LinkIcon" size="18" class="mb-04"></feather-icon>Similar</b-button>
  
        <b-button class="dark-button w-100 mt-1 mt-xl-0 d-flex justify-content-center" variant="dark-button">
          <b-avatar class="size-avatar-net mr-05" variant="light">
            <b-img :src="getIconWhite(headerData.main.network)" :class="getIconColor(headerData.main.network, false)" fluid class="size-img-net"/>
          </b-avatar>
          <span>
            {{ headerData.username }}, {{getFormat(headerData.followers)}}
          </span>
        </b-button>
      </div>
      <div class="skeleton col-12 col-md-3 d-flex justify-content-center d-md-block" v-else>
        <b-skeleton class="col-6 col-md-12 h-30"></b-skeleton>
      </div>
    </div>
  </div>    
  
</template>

<script>
import {
  BButtonGroup,
  BImg, 
  BAvatar, 
  BIcon, 
  BLink, 
  BButton,
  BSkeleton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import service from '@/services/profile'
import { getLanguage, getCountry } from '@/libs/utils/others';
import { getIconWhite, getIconColor } from '@/libs/utils/icons'
import { shortText, urlImagetoBase64, getFormat } from '@/libs/utils/formats';
import { isUrl, getAssetUrl } from '@/libs/utils/urls'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  components: {
    BButtonGroup,
    BAvatar,
    BImg,
    BIcon,
    BLink,
    BButton,
    BSkeleton,
    ImageVerified: () => import('@/views/components/image/ImageVerified.vue')
  },
  directives: {
    Ripple,
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
    info: {
      type: Object,
      default: () => {},
    },
    loaded: {
      type: Boolean,
      default: false,
    },
    metrics: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data () {
    return {
      getCountry,
      getLanguage,
      getFormat,
      isUrl,
      getAssetUrl,
      urlImagetoBase64,
      shortText,
      getIconWhite,
      getIconColor,
      network_type_map: {
        1: 'instagram',
        2: 'youtube',
        3: 'tiktok',
        4: 'twitch',
        5: 'twitter'
      },
      window,
      children: [],
      avatar: this.headerData.avatar,
      background_image: '',
      image_header: '',
      linkNetwork: '',
      languages: '',
      geo: '',
      email_influencer: null,
      children_data: null,
      loaded_children_service: false,
      total_audience: 0,
    }
  },
  beforeCreate() {
    service.getChildren(this.$route.params.network, this.$route.params.alias)
      .then(response => {
        this.loaded_children_service = true && this.loaded;
        if (response.status === 200) {
          this.children_data = response.data.data[0];
          this.getTotalAudience(this.children_data.socials)
          const children = Object.values(response.data.data[0].social_networks).filter(child => {
            const username = child.type === 2 ? child.social_id.replace(`${child.username.toLowerCase()}-`, '') : child.social_id;
            const self = this;
            if (username === this.$route.params.alias && this.typeToNetwork(child.type) === this.$route.params.network) {
              urlImagetoBase64(child.avatar_url, function (result) {
                self.avatar = result
              });
            }
          });
          this.children = children
        }
      })
      .catch(() => {
        this.loaded_children_service = true && this.loaded;
      })

    service.getCover()
      .then(response => {
        if (response.cover_url) {
          if (isUrl(response.cover_url)) {
            this.image_header = response.cover_url
          } else {
            this.image_header = getAssetUrl(response.cover_url)
          }

          const self = this;

          urlImagetoBase64(this.image_header, function (result) {
            self.background_image = {
              "background-image": `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%), url('${result}')`,
            }
          });
        } else {
          this.background_image = {
            "background-image": `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%), url()`,
          }
        }
      })
  },
  computed: {
    is_in_creator_view() {
      return Object.keys(this.metrics).length > 0
    },
  },
  created() {
    this.getLinkNetwork();
    setTimeout(() => {
      this.getGeo();
      this.getLanguages();
      this.getEmailInfluencer();
    }, 200)
    if (isUrl(this.headerData.avatar)) {
      const self = this;
      urlImagetoBase64(this.headerData.avatar, function (result) {
        self.avatar = result
      });
    }
  },
  methods: {
    openToNewTab() {
      window.open(this.linkNetwork, '_blank');
    },
    getTotalAudience(nets) {
      nets.forEach(i => {
        this.total_audience += i.subscribers_count;
      })
    },
    copyAction(link, is_host_copy = false) {
      this.$copyText(link).then(() => {
        loadToastificationContent().then((module) => {
          this.$toast({
            component: module.default,
            position: "top-right",
            props: {
              title: this.$t('campaigns.copied'),
              icon: "CopyIcon",
              text: !is_host_copy ? this.$t('campaigns.clipboardEmail') : 'Se ha copiado el link exitosamente',
              variant: 'success'
            },  
          });
        })
      });
    },
    typeToNetwork(type) {
      return this.network_type_map[type];
    },
    getEmailInfluencer() {
      if (this.headerData.main.network === 'instagram') {
        this.email_influencer = (this.info.main_child && this.info.main_child.detail.contact_details && this.info.main_child.detail.contact_details.emails && this.info.main_child.detail.contact_details.emails.length !== 0) ? this.info.main_child.detail.contact_details.emails[0] : null;
      } else {
        this.email_influencer = (this.info.report && this.info.report.features && this.info.report.features.blogger_emails && this.info.report.features.blogger_emails.data && this.info.report.features.blogger_emails.data.length !== 0) ? this.info.report.features.blogger_emails.data[0] : null;
      }
    },
    getLanguages() {
      if (this.headerData.main.network === 'instagram') {
        this.languages = this.info.main_child ? this.info.main_child.detail.blogger_languages.map(code => getLanguage(code)).reduce((str, name) => `${str},${name}`) : [];
      } else {
        this.languages = (this.info.report && this.info.report.features && this.info.report.features.blogger_languages && this.info.report.features.blogger_languages.data !== null) ? this.info.report.features.blogger_languages.data.map(code => getLanguage(code)).reduce((str, name) => `${str},${name}`) : [];
      }
    },
    getGeo() {
      if (this.headerData.main.network === 'instagram')
        this.geo = (this.info.main_child && this.info.main_child.detail.blogger_geo.length !== 0) ? getCountry(this.info.main_child.detail.blogger_geo.country) : null
      else
        this.geo = (this.info.report && this.info.report.features && this.info.report.features.blogger_geo && this.info.report.features.blogger_geo.data !== null) ? getCountry(this.info.report.features.blogger_geo.data.country) : null
    },
    getLinkNetwork() {
      const net = this.headerData.main.network;
      if (net === 'instagram') {
        this.linkNetwork = `https://www.instagram.com/${this.headerData.username}`
      } else if (net === 'tiktok') {
        this.linkNetwork = `https://www.tiktok.com/@${this.headerData.username}`
      } else if (net === 'youtube') {
        this.linkNetwork = `https://www.youtube.com/channel/${this.headerData.username_channel_id}`
      } else if (net === 'twitch') {
        this.linkNetwork = `https://www.twitch.tv/${this.headerData.username}`
      } else if (net === 'twitter') {
        this.linkNetwork = `https://twitter.com/${this.headerData.username}`
      } 
    },
    getDate(date) {
      return date.split('/')
    },
  },
  watch: {}
}
</script>

<style type="text/css" lang="scss">
.h-auto-card-header {
  position: relative;

  .image-gradient {
    width: 100%;
    height: 250px;
    background-size: cover;
    background-color: #e4e4e4;
  }

  .span-geo-profile {
    position: absolute;
    top: 1.5em;
    right: 1.5em;
    padding: 7px;
    border-radius: 8px;
    font-size: 13px;
  } 

  .icon-check {
    width: 24px;
    height: 24px;
  }
  .b-avatar-profile-header {
    width: 115px !important;
    height: 115px !important;
    object-fit: cover;
    padding: 3px;
    background-color: #e4e4e4;
    cursor: pointer;

    @media(max-width: 768px) {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    }
  }
}
.w-xl-auto {
  @media (min-width: 1200px) {
    width: auto !important;
  }
}
.container-avatar-title-description {
  position: absolute;
  top: 7.8em;
  left: 1.5em;
  max-height: 116px;
  @media(max-width: 767px) {
    top: 36%;
    left: 50%;
    transform: translate(-50%, -50%);
    a {
      color: black !important;
    }
  }

  .container-p-description-designation {
    display:block;
    text-overflow:ellipsis;
    overflow:hidden;
    max-height: 65px;
    padding-right: 1.5em;
    @media(min-width: 769px) {
      display: -webkit-box !important;
      -webkit-line-clamp: 3 !important;
      -webkit-box-orient: vertical !important;
    }
  }
}
.skeleton {
  .h-18 {
    height: 24px;;
  }
  .h-28 {
    height: 36px;
  }
  .mb-02 {
    margin-bottom: 0.4em;
  }
  .h-30 {
    height: 46px;
  }
}
.flex-shrink {
  flex-shrink: 1 !important;
  display: block;
  width: 115px;
    height: 115px;
}
.p-09 {
  padding: 11px 10px;
}
.size-img-net {
  height: 20px !important;
  width: 20px
}
.size-avatar-net {
  height: 20px !important;
  width: 20px  !important;
  padding: 2px;
  background-color: white;
}
.pt-custom-action-header {
  padding: 2.5em 0 0 0;
}
.mr-05 {
  margin-right: 0.5em
}
.ml-05-r {
  @media (min-width: 768px) {
    margin-left: 0.5em;
  }
}
.mb-04 {
  margin-bottom: 0.4em;
}

  .container-dropdowns-actions {
    // position: absolute;
    //padding-left: 10em;
    padding: 1.5em;

    @media (max-width: 768px) {
      padding-top: 7.5em;
    }
    h5 {
      font-size: 18px;
      margin: 0 0 0.3em;
    }

    p {
      margin: 0;
      font-family: 'avenir-medium';
      color: #718096;
    }

    .btn-email {
      box-shadow: inset 0 0 0 1px #8490A4;
      border-radius: 0.358rem;
      button {
        color: #0f131a;
        border: none !important;

        &:last-child {
          border-left: 1px solid #8490A4 !important;
        }
        &:hover {
          background: #F3F5F8;
          color: #0f131a !important;
        }
      }
    }
    .dark-button {
      background-color: #404452;
      color: white;
      padding: 10px;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
    

    .px-05 {
      padding: 0 0.5em;
    }

    .button-no-borders {
      background: none !important;
      color: #0f131a !important;
      font-size: 12px;
      padding: 0 10px;
      // height: auto;
      // b
      border: none;
      box-shadow: none !important;
      font-family: 'avenir-medium';
      margin-bottom: 1rem;
    }
    .button-add-saved-profile-header {
      box-shadow: inset 0 0 0 1px #8490A4;
      width: 36px;
      height: 36px;
      padding: 0px;
    }
  }

</style>